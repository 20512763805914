<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{$t('message.goods_dispatch')}}</p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'close'"
                        :loading="loadingButton"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        
        <div class="card-table-header table-crm-smart custom__scroll">
            <el-table 
                header-row-class-name="header__class"
                row-class-name="row__class"
                :data="dealProductsList" 
                show-summary 
                :summary-method="getSummaries" 
                @selection-change="handleSelectionChange"
                border stripe 
                style="width: 100%">
                <el-table-column
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column :label="$t('message.deal')" width="90">
                    <template slot-scope="scope"> {{ scope.row.deal_id }} </template>
                </el-table-column>
                <el-table-column :label="$t('message.reciever')">
                    <template slot-scope="scope"> 
                        <span v-show="scope.row.reciever">
                            {{'ID: ' + scope.row.reciever.custom_id }} <br> {{ scope.row.reciever.name}} 
                        </span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('message.name')">
                    <template slot-scope="scope"> 
                        <span v-show="scope.row.type === 'product'">{{ scope.row.product ? scope.row.product.name : '' }} </span>
                        <span v-show="scope.row.type === 'package'">{{ $t('message.package') }} </span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('message.barcode')">
                    <template slot-scope="scope"> 
                        <span>{{ scope.row.barcode }} </span>    
                    </template>
                </el-table-column>
                <el-table-column :label="$t('message.quantity_y')">
                    <template slot-scope="scope"> 
                        <span>{{ scope.row.remainder }} </span>    
                        <span v-if="!scope.row.batches"> 1 </span>    
                    </template>
                </el-table-column>
                <el-table-column :label="$t('message.current_quantity')">
                    <template slot-scope="scope">
                        <div v-show="!scope.row.totalQuantity">
                            <el-input
                                :disabled="(scope.row.batches && scope.row.batches.length > 1)"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="checkValue(scope.row)"
                                v-model="scope.row.incoming_quantity"
                            ></el-input>
                        </div>
                        <span v-show="scope.row.totalQuantity">
                            {{scope.row.totalQuantity}}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column :label="columns.weight.title">
                    <template slot-scope="scope">
                        <el-input 
                            v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )"
                            :min="0" 
                            :disabled="false"
                            type="number" 
                            size="mini" 
                            v-model="scope.row.weight"
                        ></el-input>
                        <span v-show="scope.row.type === 'package'"> {{ scope.row.total_weight }} </span>
                        <span v-show="scope.row.type === 'product' && scope.row.batches && scope.row.batches.length > 1"> {{ showWeight(scope.row) }} </span>
                    </template>
                </el-table-column>

                <el-table-column :label="columns.item_weight.title">
                    <template slot-scope="scope">
                        <el-input 
                            v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )"
                            :min="0" 
                            :disabled="showInputCheck(scope.row)"
                            @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                            type="number" 
                            size="mini" 
                            v-model="scope.row.item_weight"
                        ></el-input>
                    </template>
                </el-table-column>

                <el-table-column :label="columns.item_weight_in_pounds.title">
                    <template slot-scope="scope">
                        <el-input 
                            v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 && !showInputCheck(scope.row))"
                            :min="0" 
                            @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                            type="number" 
                            size="mini" 
                            v-model="scope.row.item_weight_in_pounds"
                        ></el-input>
                    </template>
                </el-table-column>

                <el-table-column :label="columns.width.title" v-if="columns.width.show" width="90">
                    <template slot-scope="scope">
                        <el-input
                            v-show="showInputCheck(scope.row)"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="compareAndSetWeight(scope.row)"
                            v-model="scope.row.width"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column :label="columns.height.title" v-if="columns.height.show" width="90">
                    <template slot-scope="scope">
                        <el-input
                            v-show="showInputCheck(scope.row)"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="compareAndSetWeight(scope.row)"
                            v-model="scope.row.height"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column :label="columns.length.title" v-if="columns.length.show" width="90">
                    <template slot-scope="scope">
                        <el-input
                            v-show="showInputCheck(scope.row)"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="compareAndSetWeight(scope.row)"
                            v-model="scope.row.length"
                        ></el-input>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('message.comment')">
                    <template slot-scope="scope"> 
                        <span v-show="scope.row.hasOwnProperty('comment')"> {{ scope.row.comment }} </span>
                        <span  v-show="!scope.row.hasOwnProperty('comment')">{{scope.row.containerType ? scope.row.containerType.name : ''}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import transfer_mix from "@/utils/mixins/views/transfer_mix";

export default {
    mixins: [transfer_mix],
    props: {
        selectedItem: {
            default: Object
        }
    },
    data: () => ({
        transfer_type: 'sending',
    }),
    
    computed: {
        ...mapGetters({
        }),

        total_weight: function () {
            let total = 0;
            this.dealProductsList.forEach((product) => {
                if(product.type === 'product'){
                    if(product.batches && product.batches.length > 1){
                        product.batches.forEach(batch => {
                            total += parseFloat(batch.weight);
                        });
                    }else{
                        total += parseFloat(product.weight);
                    }
                }
                if(product.type === 'package'){
                    total += parseFloat(product.total_weight);
                }
            });
            return _.round(parseFloat(total) ,2);
        },
    },
    methods: {
        ...mapActions({
            emptyCortainerProducts: "packageForProductCirculations/empty",
            getTransferProducts: 'transferingCargo/show',
        }),

        updateParcelProducts(parcel) {
            this.selectedProd = parcel;
            this.dialogParcelProductsList = true;
        },
        
        async updateIncomingProducts(){
            await this.updateBatches();
            this.selectedProduct = {};
            this.batchDialog = false;
        },

        handleSelectionChange(selected) {
            this.selectedProducts = selected;
        },

        afterOpen() {
            this.getTransferProducts(this.selectedItem.id)
                .then(res => {
                })
                .catch(err => {
                })
        },
        
    },
  
}
</script>
  